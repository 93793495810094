// JS dependency
var datepicker = require('jquery-ui/ui/widgets/datepicker');
var lang = $('body').attr('data-lang');
if(lang=='fr'){
  var datepicker_fr = require('jquery-ui/ui/i18n/datepicker-fr.js');
}

// CSS dependencies
import '/../node_modules/jquery-ui/themes/base/theme.css';
import '/../node_modules/jquery-ui/themes/base/datepicker.css';

// Input Start
$("#datepicker-start").on('change', function (){
  $(".placeholder-trick.start").show();
});

$("#datepicker-end").on('change', function (){
  $(".placeholder-trick.end").show();
});

$("#datepicker-start").datepicker({
  regional: lang,
  showOn: "both",
  buttonImage: 'theme/images/icons/gmap-marker.png',
  buttonImageOnly: false,
  numberOfMonths: 1,
  minDate: 0,
  maxDate: '1Y',
  onClose: function(selectedDate) {
    $("#datepicker-end").datepicker("option", "minDate", selectedDate);
  },
  onSelect: function(sDate){
    $('.placeholder-trick.start').hide();
  },
});

// Input End
$("#datepicker-end").datepicker({
  regional: lang,
  showOn: "both",
  buttonImage: 'theme/images/icons/gmap-marker.png',
  buttonImageOnly: false,
  defaultDate: "+1w",
  maxDate: '1Y',
  numberOfMonths: 1,
  onClose: function(selectedDate) {
    $("#datepicker-start").datepicker("option", "maxDate", selectedDate);
  },
  onSelect: function(sDate){
    $('.placeholder-trick.end').hide();
  },
});

$(".placeholder-trick.start").on('click', function () {
  $("#datepicker-start").datepicker("show");
});

$(".placeholder-trick.end").on('click', function () {
  $("#datepicker-end").datepicker("show");
});


$("#datepicker-permis").datepicker({
  changeMonth: true,
  changeYear: true,
  showOn: "both",
  //buttonImage: "theme/images/sprites/resa-datepicker.png",
  dateFormat: 'mm-dd-yy'
});

$("#datepicker-born").datepicker({
  changeMonth: true,
  changeYear: true,
  showOn: "both",
  //buttonImage: "theme/images/sprites/resa-datepicker.png",
  dateFormat: 'mm-dd-yy'
});
