// Configuration
WebFontConfig = {
  google: {
    families: ['inter:100,100i,300,300i,400,400i,500,500i,600,600i', 'Material+Icons']
  }
};

// Initialisation
(function () {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();
