// Equal column height
function height_equal() {
  $('.equal-col-wp .equal-col-item').css('height', 'auto');
  $('.equal-col-wp').each(function (i) {
    var group = $(this).find('.equal-col-item');
    var tallest = 0;
    group.each(function () {
      var thisHeight = $(this).innerHeight();
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    });
    group.css('height', tallest + 5);
  });
}

function height_viewport() {
  var viewport = $(window).height();
  var header = $("#header").innerHeight();
  $('#slick-home-top .item').height(viewport - header);
}

function f_scrollDown() {
  var target = $('.home-scroll-trigger');
  target.on('click', function () {
    var scrollto = $(window).height();
    $('html, body').animate({
      scrollTop: scrollto
    }, '500');
    return false;
  });
}


// Initialisation
height_equal();
height_viewport();
f_scrollDown();

setTimeout(function () {
  height_equal();
}, 300);

$(window).on('resize', function () {
  height_equal();
});
