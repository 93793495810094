// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyD-x1dEre2o_OW-59gT9kOECilg_ppuwwM';

GoogleMapsLoader.load(function (google) {

  gmap_simple = function () {

    function gmap_simple_init() {
      var map;
      var mapLat = 14.65351;
      var mapLng = -60.93258;
      var mapZoom = 16;
      var myLatLng = {
        lat: mapLat,
        lng: mapLng
      };

      map = new google.maps.Map(document.getElementById("gmap-simple"), {
        mapTypeId: 'roadmap',
        scrollwheel: false,
        panControl: false,
        zoomControl: true,
        zoom: mapZoom,
        center: myLatLng,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: 'Hello World!',
      });
    }

    gmap_simple_init();
  }

  gmap_cluster = function () {

    var markerCluster = require('js-marker-clusterer');

    var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
      zoom: 14,
      center: {
        lat: -12.77512,
        lng: 45.22464
      }
    });

    var infoWin = new google.maps.InfoWindow();
    var markers = locations.map(function (location, i) {
      var marker = new google.maps.Marker({
        position: location
      });
      google.maps.event.addListener(marker, 'click', function (evt) {
        infoWin.setContent(location.info);
        infoWin.open(map, marker);
      });
      return marker;
    });

    var markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  }

  if ($('#gmap-simple').length) {
    gmap_simple();
  }
  if ($('#gmap-cluster').length) {
    gmap_cluster();
  }

});
