// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function () {
    $('body').toggleClass('menu-open');
    $('#nav-main').toggle();
  });
}

function filter_accordion() {
  // $('.filter-wp .item .content').hide();
  // $('.filter-wp .item .btn-wp').on('click', function () {
  //   $(document.body).trigger("sticky_kit:recalc");
  //   $(this).next().toggle();
  //   $(this).toggleClass('open');
  //   $(document.body).trigger("sticky_kit:recalc");
  // });
  $('.btn-filter').on('click', function () {
    $(this).toggleClass('open');
    $('.filter-wp .item').toggle();
  })
}

function f_header_sticky() {
  function viewportStep() {
    var viewport = $(window).width();
    if (viewport > 1200) {
      return 110;
    } else if (viewport > 991 && viewport < 1200) {
      return 80;
    } else if (viewport > 768 && viewport < 991) {
      return 93;
    }
  }

  $(window).scroll(function () {
    var scrollTop = $(this).scrollTop();
    var headerH = $('#header').innerHeight();
    if (scrollTop > viewportStep()) {
      $('body').addClass('header-fixed');
      $('body').css('paddingTop', headerH,);
    } else {
      $('body').removeClass('header-fixed');
      $('body').css('paddingTop', 0);
    }
  });
}


// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function () {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 565) {
    $('#nav-main li[data-navsub] > a').on('click', function (event) {
      $(this).next().toggle();
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function (event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function () {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function () {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function () {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function faq_toggle() {
  $('.item-faq .content').hide();
  $('.item-faq .button').on('click', function () {
    var isOpen = $(this).parents().eq(1).hasClass('open');
    if (isOpen) {
      $('.item-faq .content').hide();
      $('.item-faq').removeClass('open');
    } else {
      $('.item-faq .content').hide();
      $('.item-faq').removeClass('open');
      $(this).parent().next().toggle();
      $(this).parents().eq(1).toggleClass('open');
    }
    return false;
  });
}

function file_input() {
  $('.custom-file-input').each(function (index) {
    $(this).on("change", function (e) {
      var titleFile = e.target.files[0].name;
      $(this).next().find("span").text(titleFile)
    });
  })
}

function f_search() {
  $('#handle-search').on('click', function () {
    $('#header-top').addClass('search-open');
  });
  $('#handle-hide-search').on('click', function (e) {
    $('#header-top').removeClass('search-open');
    e.preventDefault();
  })
}

// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
faq_toggle();
//f_header_sticky();
file_input();
f_search();
filter_accordion();
