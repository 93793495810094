// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home-top').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-home-arrows',
  prevArrow: '<button class="arrow-prev mr-5"><span class="sprite arrow-carousel-left-ye-a"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-carousel-right-ye-a"></span></button>',
  responsive: [{
    breakpoint: 1200,
    settings: {}
  },
  {
    breakpoint: 991,
    settings: {}
  },
  {
    breakpoint: 576,
    settings: {}
  },
  ]
});

// Example animations
setTimeout(function () {
  $('#slick-home-top .slick-active').addClass('animation');
}, 250);

$('#slick-home-top').on('afterChange', function (event, slick, currentSlide) {
  $('#slick-home-top .slick-active').addClass('animation');
});

$('#slick-home-top').on('beforeChange', function (event, slick, currentSlide) {
  $('#slick-home-top .slick-active').removeClass('animation');
});

// Example
$('#slick-home-vertical').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  vertical: true,
  arrows: true,
  autoplay: false,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-arrows',
  prevArrow: '<button class="arrow-prev"><i class="material-icons">arrow_backward</i></button>',
  nextArrow: '<button class="arrow-next"><i class="material-icons">arrow_forward</i></button>',
  responsive: [{
    breakpoint: 1200,
    settings: {}
  },
  {
    breakpoint: 991,
    settings: {
      unslick: true
    }
  },
  {
    breakpoint: 768,
    settings: {
      vertical: false,
      adaptiveHeight: true,

    }
  },
  ]
});


setTimeout(function () {
  $('#slick-home-vertical .slick-active').addClass('animation');
}, 250);

$('#slick-home-vertical').on('afterChange', function (event, slick, currentSlide) {
  $('#slick-home-vertical .slick-active').addClass('animation');
});

$('#slick-home-vertical').on('beforeChange', function (event, slick, currentSlide) {
  $('#slick-home-vertical .slick-active').removeClass('animation');
});

$('#slick-product').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  arrows: false,
  autoplay: true,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
    breakpoint: 1200,
    settings: {}
  },
  {
    breakpoint: 991,
    settings: {}
  },
  {
    breakpoint: 576,
    settings: {
      autoplay: false
    }
  },
  ]
});

$("#slick-product-nav .button").on('click', function () {
  var number = $(this).attr('data-trigger');
  $('#slick-product').slick('slickGoTo', parseInt(number - 1), false);
})
